import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountLimitType, AccountLimitTypeState } from './types';

const initialState: AccountLimitTypeState = {
  accountLimitTypes: [],
  isLoaded: false
};

export const accountLimitTypesSlice = createSlice({
  name: 'accountLimitTypes',
  initialState,
  reducers: {
    setAccountLimitTypes: (state, action: PayloadAction<AccountLimitType[]>) => {
      state.accountLimitTypes = action.payload;
      state.isLoaded = true;
    },
    updateAccountLimitType: (state, action: PayloadAction<AccountLimitType>) => {
      const tmpAccountLimitTypes = [...state.accountLimitTypes];
      const foundItem = tmpAccountLimitTypes.find((item) => item.id === action.payload.id);
      if (foundItem) {
        // update
        foundItem.name = action.payload.name;
        foundItem.paymentMethod.id = action.payload.paymentMethod.id;
        foundItem.appearance = action.payload.appearance;
        foundItem.isActive = action.payload.isActive;
      } else {
        // add
        tmpAccountLimitTypes.unshift(action.payload);
      }
      state.accountLimitTypes = tmpAccountLimitTypes;
    },
    deleteAccountLimitType: (state, action: PayloadAction<AccountLimitType['id']>) => {
      const tmpAccountLimitTypes = [...state.accountLimitTypes];
      state.accountLimitTypes = tmpAccountLimitTypes.filter((limitType) => limitType.id !== action.payload);
    }
  }
});

export const { setAccountLimitTypes, updateAccountLimitType, deleteAccountLimitType } = accountLimitTypesSlice.actions;

export default accountLimitTypesSlice.reducer;
