import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableConfigState } from './types';

const initialState: TableConfigState = {
  visibilityModel: {}
};

export const tableConfigSlice = createSlice({
  name: 'tableConfig',
  initialState,
  reducers: {
    setVisibilityModel: (state, action: PayloadAction<TableConfigState['visibilityModel']>) => {
      state.visibilityModel = action.payload;
    }
  }
});

export const { setVisibilityModel } = tableConfigSlice.actions;

export default tableConfigSlice.reducer;
