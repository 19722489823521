import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import ThemeCustomization from 'themes';
import { ConfigProvider } from 'utils/ConfigContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider>
    <ThemeCustomization>
      <Notistack>
        <Snackbar />
        <App />
      </Notistack>
    </ThemeCustomization>
  </ConfigProvider>
);

reportWebVitals();
