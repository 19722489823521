import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod, PaymentMethodsState } from './types';

const initialState: PaymentMethodsState = {
  paymentMethods: [],
  isLoaded: false
};

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = action.payload;
      state.isLoaded = true;
    },
    updatePaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      const tmPaymentMethods = [...state.paymentMethods];
      const foundItem = tmPaymentMethods.find((item) => item.id === action.payload.id);
      if (foundItem) {
        // update
        foundItem.name = action.payload.name;
        foundItem.appearance = action.payload.appearance;
        foundItem.isActive = action.payload.isActive;
      } else {
        // add
        tmPaymentMethods.unshift(action.payload);
      }
      state.paymentMethods = tmPaymentMethods;
    },
    deletePaymentMethod: (state, action: PayloadAction<PaymentMethod['id']>) => {
      const tmpPaymentMethods = [...state.paymentMethods];
      state.paymentMethods = tmpPaymentMethods.filter((method) => method.id !== action.payload);
    }
  }
});

export const { setPaymentMethods, updatePaymentMethod, deletePaymentMethod } = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
