import axios, { AxiosError } from 'axios';
import { logout } from 'store/slices/authSlice';
import { EnhancedStore } from '@reduxjs/toolkit';
import { AuthState } from 'store/slices/types';

type Store = EnhancedStore<{ auth: AuthState }>;
function setupAxios(store: Store) {
  // REQUEST
  axios.interceptors.request.use((config) => {
    const { token } = store.getState().auth;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // RESPONSE
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err: AxiosError) => {
      if (err.response?.status === 401) {
        // Invalid token
        store.dispatch(logout());
      } else {
        return Promise.reject(err);
      }
    }
  );
}

export default setupAxios;
