import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountLimitType, CurrentAccount, CurrentAccountsState } from './types';

const initialState: CurrentAccountsState = {
  currentAccounts: [],
  isLoaded: false
};

export const currentAccountsSlice = createSlice({
  name: 'currentAccounts',
  initialState,
  reducers: {
    setCurrentAccounts: (state, action: PayloadAction<CurrentAccount[]>) => {
      state.currentAccounts = action.payload;
      state.isLoaded = true;
    },
    updateCurrentAccount: (state, action: PayloadAction<CurrentAccount>) => {
      const tmpCurrentAccounts = [...state.currentAccounts];
      const foundItem = tmpCurrentAccounts.find((item) => item.id === action.payload.id);
      if (foundItem) {
        // update
        foundItem.name = action.payload.name;
        foundItem.isActive = action.payload.isActive;
      } else {
        // add
        tmpCurrentAccounts.unshift(action.payload);
      }
      state.currentAccounts = tmpCurrentAccounts;
    },
    deleteCurrentAccount: (state, action: PayloadAction<AccountLimitType['id']>) => {
      const tmpCurrentAccounts = [...state.currentAccounts];
      state.currentAccounts = tmpCurrentAccounts.filter((currentAccount) => currentAccount.id !== action.payload);
    }
  }
});

export const { setCurrentAccounts, updateCurrentAccount, deleteCurrentAccount } = currentAccountsSlice.actions;

export default currentAccountsSlice.reducer;
