import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import AuthReducer from './slices/authSlice';
import PaymentMethods from './slices/paymentMethods';
import AccountLimitTypes from './slices/accountLimitTypes';
import CurrentAccounts from './slices/currentAccounts';
import TableConfig from './slices/tableConfigSlice';

const persistConfig = {
  key: 'root',
  storage
};

const combinedReducers = combineReducers({
  auth: AuthReducer,
  paymentMethods: PaymentMethods,
  accountLimitTypes: AccountLimitTypes,
  currentAccounts: CurrentAccounts,
  tableConfig: TableConfig
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
