import React, { createContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Loader from 'components/Loader';
import ScrollTop from 'components/ScrollTop';
import { createContextualCan } from '@casl/react';
import { defineAbility, MongoAbility } from '@casl/ability';
import bgImage from 'bg-1600x900.jpg';

// Routes
const Login = React.lazy(() => import('view/pages/auth/login'));
const TwoFactorAuthentication = React.lazy(() => import('view/pages/auth/code-verification'));
import DashboardRoutes from './DashboardRoutes';

// react casl
export const AbilityContext = createContext<MongoAbility<[any, any]>>(undefined!);
export const Can = createContextualCan(AbilityContext.Consumer);

export default function Index() {
  const { token, user } = useSelector((state: RootState) => state.auth);

  return (
    <React.Suspense fallback={<Loader />}>
      <BrowserRouter>
        <AbilityContext.Provider
          value={defineAbility((can) => {
            user.abilities.map((ability) => can(ability.action, ability.subject));
          })}
        >
          <ScrollTop>
            {!token || !user.name ? (
              <Routes>
                <Route
                  path="/"
                  element={
                    <div
                      style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <h1 style={{ color: '#fff', fontSize: '3.5em' }}>Yapım Aşamasında</h1>
                    </div>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/login/2fa" element={<TwoFactorAuthentication />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            ) : (
              <DashboardRoutes />
            )}
          </ScrollTop>
        </AbilityContext.Provider>
      </BrowserRouter>
    </React.Suspense>
  );
}
