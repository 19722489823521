import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User, USER_TYPES } from './types';

const initialState: AuthState = {
  user: {
    id: '',
    name: '',
    email: '',
    role: '',
    abilities: []
  },
  token: undefined,
  userType: undefined
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string; type: USER_TYPES }>) => {
      state.token = action.payload.token;
      state.userType = action.payload.type;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.token = undefined;
      state.user = {
        id: '',
        name: '',
        email: '',
        role: '',
        abilities: []
      };
    }
  }
});

export const { login, setUser, logout } = authSlice.actions;

export default authSlice.reducer;
